<template>
  <div class="bottom-screen" id="bottomScreen">
    <div class="content-box">
      <div class="box-item">
        <div><a href="mailto:craftmods@icloud.com" target="_blank">Contact us</a></div>
        <!-- <div><a href="https://craftmods.cn/privacy">Privacy</a> & 
        <a href="https://craftmods.cn/terms" target="_blank">Terms</a></div> -->
        <div><a href="https://xiaoruo.net/skinsart/privacy">Privacy</a> & 
        <a href="https://xiaoruo.net/skinsart/terms" target="_blank">Terms</a></div>
        <div><span>©2024 XIAORUO Inc. All Rights Reserved</span></div>
      </div>

      <div><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023083723号-1</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomScreen',

  data() {
    return {
    };
  },

  methods: {
  }
}
</script>

<style scoped>
.bottom-screen {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  width: 100%;
  height: 185px;
  background: #140C20;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  color: #6D6D6D;
  margin: 46px 20px 0 20px;
  max-width: 1100px;

  font-size: 26px;
  font-family: "Urbanist-SemiBold";
}

.content-box a {
  color: #6D6D6D;
}

.box-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  width: 100%;
  margin-bottom: 30px;
}

@media (max-width: 880px) {
  .box-item {
    margin: 0;
  }

  .content-box {
    font-size: 12px;
    min-width: 230px;
    margin: 22px 0 38px 0;
  }

  .box-item {
    align-items: center;
    flex-direction: column;
  }

  .box-item > div {
    margin-bottom: 20px;
  }
}
</style>
