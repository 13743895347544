<template>
  <div class="second-screen" id="secondScreen">
    <div class="content-box">
      <div class="box-tube">
        <div class="tube-app-desc">
          <img :src="tubeIcon">
          <div>
            <div class="tube-app-name">TubeCut</div>
            <div class="tube-app-title">THUMBNAIL DESIGN & MAKER</div>
            <div class="tube-app-link">
              <!-- <a href="https://craftmods.cn/tubecut" target="_blank">tubecut.me</a> -->
              <a href="https://tubecut.me" target="_blank">tubecut.me</a>
            </div>
          </div>
        </div>

        <div class="tube-app-star">
          <img :src="starIcon">
          <span>185762 Reviews</span>
        </div>

        <div class="tube-app-introduce">
          <div>
            TubeCut packs the power of pro-grade thumbnail editing software into a wonderfully
            intuitive mobile app. <br><br> It provides stunning templates and powerful design tools to create
            amazing YouTube thumbnails, intro & outro videos for you, boosting your video
            click-through rate.
          </div>
        </div>

        <div class="tube-app-download">
          <a :href="appleHref" target="_blank" @click="toApple"><img :src="appleDownloadIcon"></a>
        </div>
      </div>

      <div class="box-imgs">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) of imgs" :key="index">
            <img :src="item" class="box-img-item">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { statisticsReq } from '@/utils';

import { DOWNLOAD_TUBE_CUT_APPLE_PATH } from '@/constants';

export default {
  name: 'SecondScreen',

  data() {
    return {
      tubeIcon: require('@/assets/tubecut-icon.webp'),
      starIcon: require('@/assets/star-icon.webp'),
      appleDownloadIcon: require('@/assets/apple-download.webp'),

      imgs: [
        require('@/assets/tube-1.png'),
        require('@/assets/tube-2.png'),
        require('@/assets/tube-3.png'),
        require('@/assets/tube-4.png'),
        require('@/assets/tube-5.png'),
        require('@/assets/tube-6.png'),
        require('@/assets/tube-7.png')
      ],
      
      swiperOption: {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },

      appleHref: DOWNLOAD_TUBE_CUT_APPLE_PATH
    };
  },

  methods: {
    toApple() {
      statisticsReq(4);
    }
  }
}
</script>

<style scoped>
.second-screen {
  display: flex;
  justify-content: center;

  width: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.1s ease;
  background-image: url('@/assets/tube-bg-pad.webp');
}

.content-box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 98%;
  margin: 130px 0;
  max-width: 1320px;
}

.box-tube {
  margin: 0 15px;
  max-width: 800px;
}

.tube-app-desc {
  display: flex;
  align-items: center;
}

.tube-app-desc img {
  width: 16%;
  margin-right: 14px;
  border-radius: 20%;
}

.tube-app-name {
  font-size: calc(1.7rem + 1.5vw);
  color: #000000;
  font-family: "Urbanist-Bold";
}

.tube-app-title {
  font-size: calc(0.45rem + 1vw);
  color: #000000;
  font-family: "Urbanist-SemiBold";
}

.tube-app-link a {
  color: #3088EF;
  font-family: "Urbanist-SemiBold";
}

.tube-app-link a::before {
  position: relative;
  top: 1px;
  content: url('@/assets/link.svg');
  margin-right: 6px;
}

.tube-app-star {
  display: flex;
  align-items: center;

  margin: 42px 0 25px 0;
}

.tube-app-star img {
  width: 28%;
  margin-right: 22px;
}

.tube-app-star span {
  font-size: calc(0.1rem + 1.5vw);
  color: #000000;
  font-family: "Urbanist-SemiBold";
}

.tube-app-introduce {
  font-size: calc(0.45rem + 1vw);
  color: #1F1F1F;
  font-family: "Poppins-Regular";
  margin-bottom: 40px;
  line-height: 30px;
}

.tube-app-download {
  display: flex;
}

.tube-app-download img {
  width: 100%;
}

.tube-app-download a {
  width: 30%;
  margin-right: 26px;
}

.box-imgs {
  width: 28%;
  margin: 0 15px;
}

.box-img-item {
  width: 100%;
  border-radius: 30px;
}

.swiper-slide {
  transition: 300ms;
  opacity: 0 !important;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  opacity: 1 !important;
}

@media (max-width: 750px) {
  .second-screen {
    background-image: unset;
  }

  .content-box {
    flex-direction: column-reverse;

    width: 100%;
    margin: 66px 0 0 0;
  }

  .box-imgs {
    width: 100%;
    max-width: unset;
    margin-bottom: 42px;
    padding-top: 35px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.1s ease;
    background-image: url('@/assets/tube-bg-phone.webp');
  }

  .swiper-container {
    width: 80%;
  }
  
  .tube-app-desc img {
    width: 19%;
  }

  .tube-app-name {
    font-size: calc(1.3rem + 1.5vw);
  }

  .tube-app-title {
    font-size: calc(0.5rem + 1vw);
  }

  .tube-app-star {
    min-width: 230px;
    margin: 18px 0;
  }

  .tube-app-star img {
    width: 36%;
  }

  .tube-app-star span {
    font-size: calc(0.7rem + 1.5vw);
  }

  .tube-app-introduce {
    font-size: calc(0.7rem + 1vw);
    margin-bottom: 28px;
    line-height: 18px;
  }

  .tube-app-download {
    justify-content: center;
  }

  .tube-app-download a {
    width: 42%;
    margin: 0;
  }

  .tube-app-download a:nth-child(2) {
    display: none;
  }
}
</style>
