import { DEVICE_TYPE_ENUM } from "@/constants";

const device = require("current-device").default;

export const getWindowHeight = () => {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export const deviceType = () => {
  if (device.windows() || device.windowsPhone() || device.windowsTablet()) {
    return DEVICE_TYPE_ENUM.WINDOW;
  }

  if (device.android() || device.androidPhone() || device.androidTablet()) {
    return DEVICE_TYPE_ENUM.ANDROID;
  }

  if (device.ios() || device.ipad() || device.ipod() || device.iphone() || device.macos()) {
    return DEVICE_TYPE_ENUM.IOS;
  }

  return DEVICE_TYPE_ENUM.UNKNOWN;
}

export const statisticsReq = (statisticsType = 0) => {
  //1.创建对象
  const xhr = new XMLHttpRequest();

  //2.初始化，设置请求方法和url
  let url = 'https://xiaoruo.net:8087/cgi/ca_web_statistics';
  url += '?statistics_type=' + statisticsType;
  url += '&device_type=' + deviceType();
  xhr.open('GET', url);

  //3.发送
  xhr.send();
};
