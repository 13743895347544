<template>
  <div class="first-screen">
    <div class="box-nav">
      <div class="box-nav-inner">
        <div class="nav-logo"><img :src="logoIcon"><a>XIAORUO TECHNOLOGY</a></div>
        <div class="nav-us">
          <a href="javaScript:;" @click="goToProducts">PRODUCTS</a>
          <a href="javaScript:;" @click="goToAboutUs">ABOUT US</a>
        </div>
      </div>
    </div>

    <div class="content-box">
      <div class="box-desc"><img :src="textIcon"></div>

      <div class="box-desc-phone"><img :src="textIcon"></div>

      <div class="box-button" @click="goToProducts">START EXPLORING</div>
    </div>
  </div>
</template>

<script>
import { getWindowHeight } from '@/utils';

export default {
  name: 'FirstScreen',

  data() {
    return {
      textIcon: require('@/assets/nav-text-pad.png'),
      logoIcon: require('@/assets/xiaoruo-icon.svg'),
    };
  },

  mounted() {
    const screen = document.querySelector('.first-screen');
    screen.style.height = getWindowHeight() + 'px';
  },

  methods: {
    goToProducts() {
      const secondScreen = document.getElementById('secondScreen');
      secondScreen.scrollIntoView({ behavior: 'smooth' });
    },

    goToAboutUs() {
      const bottomScreen = document.getElementById('bottomScreen');
      bottomScreen.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.first-screen {
  display: flex;
  justify-content: center;

  position: relative;

  width: 100%;
  overflow-x: hidden;
  margin-left: auto;
  overflow-y: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.1s ease;
  background-image: url('@/assets/yiqux-bg-pad.webp');
}

.box-nav {
  width: 100%;
  height: 82px;
}

.box-nav-inner {
  height: 100%;
  margin: 0 auto;
  padding: 0 26px;
  max-width: 1320px;

  color: #FFFFFF;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-box {
  position: absolute;
  top: 45%;
  transform: translate(0, -45%);

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 98%;
  color: #FFFFFF;
  max-width: 1320px;
  min-width: 320px;
}

.box-desc {
  width: 50%;
}

.box-desc img, .box-desc-phone img {
  width: 100%;
}

.box-desc-phone {
  display: none;
}

.box-button {
  margin-top: 76px;
  padding: 1vw 3.1vw;
  max-height: 88px;
  cursor: pointer;

  border-radius: 132px;
  background: linear-gradient( 90deg, #4383FF 0%, #B336FF 100%);

  text-align: center;
  font-size: calc(0.4rem + 1.5vw);
  font-family: "Urbanist-ExtraBold";
}

.nav-us a {
  color: #FFFFFF;
}

.nav-us a:nth-child(1) {
  margin: 0 70px;
}

.box-nav div:nth-child(1) {
  font-size: 24px;
  font-family: "Urbanist-Bold";
}

.box-nav div:nth-child(2) {
  font-size: 24px;
  font-family: "Urbanist-SemiBold";
}

.nav-logo {
  display: flex;
  align-items: center;
}

.nav-logo img {
  height: 40px;
  margin-right: 24px;
}

@media (max-width: 750px) {
  .first-screen {
    background-image: url('@/assets/yiqux-bg-phone.webp');
  }

  .content-box {
    min-width: 320px;
  }

  .nav-us a:nth-child(1) {
    display: none;
  }

  .box-nav {
    height: 60px;
  }

  .box-nav div:nth-child(1) {
    font-size: 16px;
  }

  .box-nav div:nth-child(2) {
    font-size: 16px;
  }

  .box-nav-inner {
    min-width: 180px;
  }

  .nav-logo img {
    height: 28px;
  }

  .nav-logo a {
    display: none;
  }

  .box-desc {
    display: none;
  }

  .box-desc-phone {
    display: flex;
    justify-content: center;
  }

  .box-desc-phone img {
    width: 90%;
  }

  .box-button {
    padding: 3.1vw 6.5vw;
    font-size: calc(0.9rem + 1.5vw);
  }
}
</style>
