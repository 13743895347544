<template>
  <div class="fourth-screen">
    <div class="content-box">
      <div class="box-title">ABOUT US</div>
      <div class="box-desc">
        XIAORUO CO. LTD. is a professional APP development company composed of a passionate, energetic, and
        responsible team of young individuals. Since its establishment, the company has always
        adhered to the principle of prioritizing user experience and is dedicated to creating
        high-quality products. 
      </div>

      <div class="box-imgs">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) of imgs" :key="index">
            <img :id="index" :src="item">
          </swiper-slide>
        </swiper>
      </div>

      <div class="box-imgs-phone">
        <swiper :options="swiperOptionPhone">
          <swiper-slide v-for="(item, index) of imgs" :key="index">
            <img :id="index" :src="item">
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FourthScreen',

  data() {
    return {
      imgs: [
        require('@/assets/about-us-1.webp'),
        require('@/assets/about-us-2.webp'),
        require('@/assets/about-us-3.webp')
      ],

      swiperOption: {
        spaceBetween: 20,
        slidesPerView: 3
      },

      swiperOptionPhone: {
        centeredSlides: true,
        spaceBetween: 10,
        slidesPerView: 1.4,
        speed: 1500,
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    };
  },

  methods: {
  }
}
</script>

<style scoped>
.fourth-screen {
  display: flex;
  justify-content: center;

  position: relative;

  width: 100%;
  min-height: 900px;

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  transition: background-image 0.1s ease;
  background-image: url('@/assets/us-bg-pad.webp');
}

.content-box {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 98%;
  color: #FFFFFF;
  max-width: 1400px;
  min-width: 320px;
  margin: 143px 0 77px 0;
}

.box-title {
  font-size: calc(1.95rem + 1.5vw);
  color: #FFFFFF;
  font-family: "Urbanist-ExtraBold";
}

.box-desc {
  max-width: 1000px;
  margin-top: 72px;

  font-size: calc(0.4rem + 1vw);
  color: #FFFFFF;
  text-align: center;
  font-family: "Poppins-Regular";
}

.box-imgs, .box-imgs-phone {
  display: flex;
  justify-content: space-around;

  width: 100%;
  margin-top: 41px;
}

.box-imgs img {
  width: 100%;
  border-radius: 28px;
}

.box-imgs-phone {
  display: none;
}

@media (max-width: 750px) {
  .fourth-screen {
    min-height: unset;
    background-image: url('@/assets/us-bg-phone.webp');
  }

  .content-box {
    margin: 66px 0 71px 0;
  }

  .box-title {
    font-size: calc(1.3rem + 1.5vw);
  }

  .box-desc {
    margin: 33px 25px 44px 25px;
    font-size: calc(0.7rem + 1vw);
  }

  .box-imgs {
    display: none;
  }

  .box-imgs-phone {
    display: block;
  }

  .box-imgs-phone img {
    width: 100%;
    border-radius: 18px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
  
    position: relative;
    bottom: -23px;

    width: 100%;  
  }

  .swiper-pagination >>> .swiper-pagination-bullet-active {
    background: #59399E !important;
  }

  .swiper-pagination >>> .swiper-pagination-bullet {
    background: #4E485A;
    margin: 0 5px;
  }
}
</style>
