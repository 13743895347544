<template>
  <div id="app" class="yiqux-app">
    <AppFirstScreen></AppFirstScreen>

    <AppSecondScreen></AppSecondScreen>

    <AppThirdScreen></AppThirdScreen>

    <AppFourthScreen></AppFourthScreen>

    <AppBottomScreen></AppBottomScreen>
  </div>
</template>

<script>
import AppFirstScreen from './components/AppFirstScreen.vue'
import AppSecondScreen from './components/AppSecondScreen.vue'
import AppThirdScreen from './components/AppThirdScreen.vue'
import AppFourthScreen from './components/AppFourthScreen.vue'
import AppBottomScreen from './components/AppBottomScreen.vue'

import { statisticsReq } from './utils'

export default {
  name: 'App',
  components: {
    AppFirstScreen,
    AppSecondScreen,
    AppThirdScreen,
    AppFourthScreen,
    AppBottomScreen
  },

  created() {
    statisticsReq(3);
  },

  // 图片预加载
  beforeCreate() {
    let count = 0;

    // 用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片
    // 因为打包之后的图片名称会有一个加密的字符串
    const imgs = [
      require('@/assets/yiqux-bg-pad.webp'),
      require('@/assets/yiqux-bg-phone.webp'),
      require('@/assets/us-bg-pad.webp'),
      require('@/assets/tube-bg-pad.webp'),
      require('@/assets/craft-bg-pad.webp'),
      require('@/assets/craft-bg-phone.webp'),

      require('@/assets/nav-text-pad.png'),

      require('@/assets/tubecut-icon.webp'),
      require('@/assets/craftmods-icon.webp'),

      require('@/assets/star-icon.webp'),

      require('@/assets/apple-download.webp'),

      require('@/assets/craft-1.png'),
      require('@/assets/craft-2.png'),
      require('@/assets/craft-3.png'),
      require('@/assets/craft-4.png'),
      require('@/assets/craft-5.png'),

      require('@/assets/tube-1.png'),
      require('@/assets/tube-2.png'),
      require('@/assets/tube-3.png'),
      require('@/assets/tube-4.png'),
      require('@/assets/tube-5.png'),
      require('@/assets/tube-6.png'),
      require('@/assets/tube-7.png'),

      require('@/assets/about-us-1.webp'),
      require('@/assets/about-us-2.webp'),
      require('@/assets/about-us-3.webp'),
    ];

    for (let img of imgs) {
      let image = new Image();
      image.src = img;
      image.onload = () => {
        count++;
      };
    }

    return count;
  }
}
</script>

<style>
.yiqux-app {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
  background: #F2F2F2;
}

body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
  background: white;
}

a {
  text-decoration: unset;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url('@/assets/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url('@/assets/Urbanist-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: url('@/assets/Urbanist-ExtraBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist-SemiBold";
  src: url('@/assets/Urbanist-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}
</style>
