<template>
  <div class="third-screen">
    <div class="content-box">
      <div class="box-craft">
        <div class="craft-app-desc">
          <img :src="craftIcon">
          <div>
            <div class="craft-app-name">Craft Mods</div>
            <div class="craft-app-title">MCPE ADDON & SKIN EDITOR </div>
            <div class="craft-app-link">
              <!-- <a href="https://craftmods.cn/craftmods" target="_blank">craftmods.me</a> -->
              <a href="https://craftmods.me" target="_blank">craftmods.me</a>
            </div>
          </div>
        </div>

        <div class="craft-app-star">
          <img :src="starIcon">
          <span>1697803 Reviews</span>
        </div>

        <div class="craft-app-introduce">
          Craft Mods is an innovative app designed to elevate the Minecraft gaming experience by
          enabling players to create and customize their own add-ons. <br><br> With an intuitive user
          interface, this app empowers both novice and experienced Minecraft enthusiasts to craft
          unique game elements such as textures, skins, and mods. 
        </div>

        <div class="craft-app-download">
          <a :href="appleHref" target="_blank" @click="toApple"><img :src="appleDownloadIcon"></a>
          <a :href="googleHref" target="_blank" @click="toGoole"><img :src="googleDownloadIcon"></a>
        </div>
      </div>

      <div class="box-imgs">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) of imgs" :key="index">
            <img :src="item" class="box-img-item">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { statisticsReq } from '@/utils';

import { DOWNLOAD_CRAFT_MODS_APPLE_PATH, DOWNLOAD_CRAFT_MODS_GOOGLE_PATH } from '@/constants';

export default {
  name: 'ThirdScreen',

  data() {
    return {
      craftIcon: require('@/assets/craftmods-icon.webp'),
      starIcon: require('@/assets/star-icon.webp'),
      appleDownloadIcon: require('@/assets/apple-download.webp'),
      googleDownloadIcon: require('@/assets/google-download.webp'),

      imgs: [
        require('@/assets/craft-1.png'),
        require('@/assets/craft-2.png'),
        require('@/assets/craft-3.png'),
        require('@/assets/craft-4.png'),
        require('@/assets/craft-5.png')
      ],
      
      swiperOption: {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },

      appleHref: DOWNLOAD_CRAFT_MODS_APPLE_PATH,
      googleHref: DOWNLOAD_CRAFT_MODS_GOOGLE_PATH
    };
  },

  methods: {
    toApple() {
      statisticsReq(5);
    },

    toGoole() {
      statisticsReq(6);
    }
  }
}
</script>

<style scoped>
.third-screen {
  display: flex;
  justify-content: center;

  width: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.1s ease;
  background-image: url('@/assets/craft-bg-pad.webp');
}

.content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  width: 98%;
  margin: 130px 0;
  max-width: 1320px;
}

.box-craft {
  margin: 0 15px;
  max-width: 800px;
}

.craft-app-desc {
  display: flex;
  align-items: center;
}

.craft-app-desc img {
  width: 16%;
  margin-right: 14px;
  border-radius: 20%;
}

.craft-app-name {
  font-size: calc(1.7rem + 1.5vw);
  color: #000000;
  font-family: "Urbanist-Bold";
}

.craft-app-title {
  font-size: calc(0.45rem + 1vw);
  color: #000000;
  font-family: "Urbanist-SemiBold";
}

.craft-app-link a {
  color: #3088EF;
  font-family: "Urbanist-SemiBold";
}

.craft-app-link a::before {
  position: relative;
  top: 1px;
  content: url('@/assets/link.svg');
  margin-right: 6px;
}

.craft-app-star {
  display: flex;
  align-items: center;

  margin: 42px 0 25px 0;
}

.craft-app-star img {
  width: 28%;
  margin-right: 22px;
}

.craft-app-star span {
  font-size: calc(0.1rem + 1.5vw);
  color: #000000;
  font-family: "Urbanist-SemiBold";
}

.craft-app-introduce {
  font-size: calc(0.45rem + 1vw);
  color: #1F1F1F;
  font-family: "Poppins-Regular";
  margin-bottom: 40px;
  line-height: 30px;
}

.craft-app-download {
  display: flex;
}

.craft-app-download img {
  width: 100%;
}

.craft-app-download a {
  width: 30%;
}

.craft-app-download a:nth-child(1) {
  margin-right: 26px;
}


.box-imgs {
  width: 28%;
  margin: 0 15px;
}

.box-img-item {
  width: 100%;
  border-radius: 30px;
}

.swiper-slide {
  transition: 300ms;
  opacity: 0 !important;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  opacity: 1 !important;
}

@media (max-width: 750px) {
  .third-screen {
    margin-bottom: 48px;
    background-image: unset;
  }

  .content-box {
    flex-direction: column-reverse;

    width: 100%;
    margin: 66px 0 0 0;
  }

  .box-imgs {
    width: 100%;
    max-width: unset;
    margin-bottom: 42px;
    padding-top: 35px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.1s ease;
    background-image: url('@/assets/craft-bg-phone.webp');
  }

  .swiper-container {
    width: 80%;
  }
  
  .craft-app-desc img {
    width: 19%;
  }

  .craft-app-name {
    font-size: calc(1.3rem + 1.5vw);
  }

  .craft-app-title {
    font-size: calc(0.5rem + 1vw);
  }

  .craft-app-star {
    min-width: 230px;
    margin: 18px 0;
  }

  .craft-app-star img {
    width: 36%;
  }

  .craft-app-star span {
    font-size: calc(0.7rem + 1.5vw);
  }

  .craft-app-introduce {
    font-size: calc(0.7rem + 1vw);
    margin-bottom: 28px;
    line-height: 18px;
  }

  .craft-app-download {
    justify-content: center;
  }

  .craft-app-download a {
    width: 42%;
  }

  .craft-app-download a:nth-child(3) {
    display: none;
  }
}
</style>
